import React, { useEffect } from 'react';
import { Col } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import { updateMetadata } from '../../../actions/action.pipeline-input';
import FileSelector from '../../../components/FileSelector/FileSelector';
import ProteinSelector from '../../../components/ProteinSelector/ProteinSelector';
import { IPipeline, TPipeline } from '../../../types/type.pipeline';
import { IStore } from '../../../types/store';
import { TDispatch } from '../../../types/action';
import FormGroupCustom from '../../../components/Form/FormGroupCustom';
import { INPUT_SMALL } from '../../../utils/constants';
import TabbedMetaContainer from '../../../components/Views/TabbedMetaContainer';
const Curate = () => {
    const pipeline: IPipeline = useSelector((state : IStore) => state.pipeline);
    const dispatch = useDispatch<TDispatch<TPipeline>>();
    const { input, isAdvanced, isShiny } = pipeline;

    useEffect(() => {
        if (!isShiny) {
            dispatch(updateMetadata('isShiny', true));
        }
    }, [isShiny])

    return (
        <TabbedMetaContainer
            name="Curate"
            isDisabled={(tab) => tab.id === 'metamarker' && _.isEmpty(input.bulk)}
            containerClass="curate"
            metamarker={ null
        }>
            <div className="pipeline-form">
                {/* input scatter */}
                <FormGroupCustom
                    pipeline="curate"
                    id="inputcurate"
                    input={input.input}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <FileSelector
                            name='input'
                            tray={input.input}
                            accept={['csv', '.csv']}
                            getPreviousPipelineOutput={false}
                            isShowPreviousPipeline={false}
                            isMultiple={false}
                        />
                    </Col>
                </FormGroupCustom>

                {/* input meta */}
                <FormGroupCustom
                    pipeline="curate"
                    id="metacurate"
                    input={input.meta}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <FileSelector
                            name='meta'
                            tray={input.meta}
                            accept={['csv', '.csv']}
                            checkFcsMetadata={false}
                            getPreviousPipelineOutput={false}
                            isShowPreviousPipeline={false}
                            isMultiple={false}
                        />
                    </Col>
                </FormGroupCustom>

                {/* input abfiles */}
                <FormGroupCustom
                    pipeline="curate"
                    id="abfilescurate"
                    input={input.abfiles}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <FileSelector
                            name='abfiles'
                            tray={input.abfiles}
                            accept={['ab1', '.ab1']}
                            checkFcsMetadata={false}
                            getPreviousPipelineOutput={false}
                            isShowPreviousPipeline={false}
                            isMultiple={true}
                        />
                    </Col>
                </FormGroupCustom>
            </div>
        </TabbedMetaContainer>
    );
}

export default Curate;
